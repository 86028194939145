<script>
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";
import { VclCode } from 'vue-content-loading';

import {with_acrf_token} from "@/methods";


export default {

  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId'
    ])
  },
  components: {
    VclCode
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/support/status', {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.data = data;
            this.ready = true;
          })
          .catch(error => {
            this.handleError(error);
          });
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      data: null
    }
  }
};
</script>

<template>
  <div class="col-lg-4">
    <template v-if="ready">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('support.view.status.title')}}</h5>
              <hr>
              <div class="row mt-2">
                <div class="col-lg-4 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    Total Tickets
                  </h6>
                </div>
                <div class="col-lg-8 col-sm-12 text-white">
                  {{ data.tickets.total }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    Open Tickets
                  </h6>
                </div>
                <div class="col-lg-8 col-sm-12 text-white">
                  {{ data.tickets.open }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Error -->
    <template v-else-if="error">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Loading -->
    <template v-else>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('support.view.status.title')}}</h5>
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>